import React from "react";

let fields = {
    date: "When",
    team: "Who",
    location: "Where",
    special_thanks: "Special Thanks",
};

const Meta = (props) => {
    let fieldTemplate = (key, term, data) => (
        <div key={key}>
            <dt>{term}</dt>
            <dd>{data}</dd>
        </div>
    );

    let downloadLink = (name, file) => {
        // console.log(name, file);
        return (
            <a href={file.publicURL} target="_blank" rel="noopener noreferrer">
                {name}
            </a>
        );
    };

    let terms = Object.keys(props.data)
        .map((k, i) =>
            Object.keys(fields).includes(k) && props.data[k]
                ? fieldTemplate("field_" + i, fields[k], props.data[k])
                : null
        )
        .filter((k) => k);

    // console.log(Object.keys(props.data));
    let download =
        Object.keys(props.data).includes("download") && props.data.download
            ? fieldTemplate(
                  "download",
                  "Download",
                  downloadLink(props.data.downloadName, props.data.download)
              )
            : null;

    return (
        <section className="post">
            {terms.length ? (
                <>
                    <h3>Project Information</h3>
                    <dl>
                        {terms}
                        {download}
                    </dl>
                </>
            ) : null}
        </section>
    );
};

export { Meta };
