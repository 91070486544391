import React from "react";
import { graphql } from "gatsby";
import rehypeReact from "rehype-react";
import { Layout } from "../layouts/layout";
import { PostHeader } from "../components/header";
import { PostList } from "../components/post-list";
import { Meta } from "../components/meta";
import { capitalize } from "../utils";

const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: { "post-header": PostHeader, h2: PostHeader },
}).Compiler;

const PostCoda = (props) => {
    const { data, radioValue, post } = props;

    let projects = data.allMarkdownRemark.edges.filter((edge) =>
        edge.node.frontmatter.category.includes(capitalize(radioValue))
    );

    const index = projects.findIndex((edge) => edge.node.id === post.id);

    const start = index + 1;

    const end = start + 2;

    projects = projects.concat(projects).slice(start, end); // Wrap around to the start of the category

    return (
        <>
            <div className={"nav-divider ml3 mb4 w-100"}></div>
            <h3 className="ph3">{`More ${capitalize(radioValue)} Projects`}</h3>
            <PostList projects={projects} />
        </>
    );
};

const PostBody = (props) => {
    const post = props.data.markdownRemark;
    return (
        <>
            <article className="post mb4 ph3 backround-white lato">
                {renderAst(post.htmlAst)}
                <div className={"nav-divider mt4 w-100"}></div>
                <Meta data={post.frontmatter}></Meta>
            </article>
            <PostCoda post={post} {...props} />
        </>
    );
};

const BlogPost = (props) => {
    // console.log("blogpost", props);
    return (
        <Layout>
            <PostBody id={props.pageContext.slug} {...props}></PostBody>
        </Layout>
    );
};

export default BlogPost;

export { PostBody };

export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            htmlAst
            frontmatter {
                title
                published
                date
                pub_date(formatString: "MMMM YYYY")
                team
                location
                special_thanks
                download {
                    publicURL
                }
                downloadName
            }
        }
        allMarkdownRemark(
            filter: { frontmatter: { published: { ne: false } } }
            sort: { fields: [frontmatter___pub_date], order: DESC }
        ) {
            totalCount
            edges {
                node {
                    id
                    frontmatter {
                        title
                        date
                        location
                        published
                        category
                        featuredImage {
                            childImageSharp {
                                fluid(
                                    maxWidth: 300
                                    maxHeight: 300
                                    cropFocus: CENTER
                                    toFormat: PNG
                                    quality: 80
                                ) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    fields {
                        slug
                    }
                    excerpt
                    htmlAst
                }
            }
        }
    }
`;
